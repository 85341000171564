import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import loadable from '@loadable/component'
import Slider from 'react-slick'
import SimpleReactLightbox from 'simple-react-lightbox'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { motion } from 'framer-motion'
export {
  React,
  ReactDOM,
  axios,
  loadable,
  Slider,
  SimpleReactLightbox,
  CSSTransition,
  TransitionGroup,
  motion
}

export * from 'react'
export * from 'react-dom'
export * from 'react-router-dom'
export * from 'axios'
export * from '@loadable/component'
export * from 'react-lazy-load-image-component'
export * from 'react-bootstrap'
export * from 'react-slick'
export * from 'simple-react-lightbox'
export * from 'react-transition-group'
export * from 'framer-motion'
